<template>
  <div class="card p-2">
    <div class="d-flex flex-wrap justify-content-space">
      <div
        class="mr-2"
        style="
          height: 64px;
          padding: 7px;
          padding-top: 18px;
          width: 64px;
          border-radius: 12px;
          overflow: hidden;
          background-color: #f2f2f2;
        "
      >
        <img
          style="width: 100%"
          :src="vehicleInfo.profile_pic"
          alt=""
          srcset=""
        />
      </div>
      <div>
        <strong>B-790632</strong>
        <div
          style="
            background-color: #fafafa;
            padding: 3px 18px;
            margin-top: 4px;
            border-radius: 12px;
          "
        >
          <strong> Contact</strong>
          <div>
            <span> {{ vehicleInfo.phone_number }}</span>
          </div>
          <div>
            <span> {{ vehicleInfo.email }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["vehicle_info"],
  computed: {
    vehicleInfo() {
      return {
        profile_pic:
          this.vehicle_info.owner_id.profile_pic ||
          require("@/assets/images/no-car.png"),
        phone_number: `+ ${this.vehicle_info.owner_id.country_code} - ${this.vehicle_info.owner_id.phone_number}`,
        email: this.vehicle_info.owner_id.email || "Email not available",
      };
    },
  },
};
</script>

<style></style>
