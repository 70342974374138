<template>
  <div class="card px-4 py-3" style="min-height: 114px">
    <strong>Vehicle info</strong>

    {{ vehicle.plate_category }} - {{ vehicle.plate_number }}
    <div v-if="vehicle.model">
      Make: {{ vehicle.model.manufacture.name }} ({{ vehicle.model.name }}
      )
    </div>
  </div>
</template>

<script>
export default {
  props: ["vehicle"],
};
</script>

<style>
.vehicle-info {
  min-height: 100px;
  min-width: 280px;
}
</style>
