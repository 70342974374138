<template>
  <div class="job-details-wrapper">
    <div>
      <job-status :status_time="jobStatusAndTime" />
    </div>
    <div class="row">
      <div class="col-sm-6">
        <vehicle-info :vehicle="vehicleInfo" />
      </div>
      <div class="col-sm-6">
        <driver :vehicle_info="vehicleInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import JobStatus from "./job-status.vue";
import VehicleInfo from "./vehicle-info.vue";
import Driver from "./driver.vue";

export default {
  props: ["job"],
  components: {
    JobStatus,
    VehicleInfo,
    Driver,
  },
  computed: {
    jobStatusAndTime() {
      return {
        status: this.job.status,
        time: this.job.status === 1 ? this.job.wrapping_schedule_date : null,
      };
    },
    vehicleInfo() {
      return this.job.vehicle;
    },
  },
};
</script>

<style></style>
