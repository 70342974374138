<template>
  <div class="text-center mb-3">
    <strong>Job status : {{ jobStaus[status_time.status] }}</strong> 
    <div v-if="status_time.time">
        Time : <strong>{{ moment(status_time.time).format("LLL") }}</strong>
    </div>
  
  </div>
</template>

<script>
import { jobStaus } from "@/config/data/status";
export default {
  props: ["status_time"],
  data() {
    return {
      jobStaus: jobStaus,
    };
  },
};
</script>

<style></style>
