<template>
  <div>
    <div class="my-3">
      <b-skeleton class="m-auto" width="25%"></b-skeleton>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </div>
      <div class="col-sm-6">
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </div>
    </div>
     <div class="my-3">
      <b-skeleton class="m-auto" width="18%"></b-skeleton>
    </div>
    <b-skeleton-img></b-skeleton-img>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
