<template>
  <Layout>
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <Skelton />
      </template>
      <div v-if="job">
        <top-wrapper :job="job" />
        <div
          style="
            text-align: center;
            background-color: white;
            padding: 18px;
            border-radius: 8px;
          "
        >
          <strong style="font-weight: 800"> Sticker Design</strong>
          <img class="job-details-sample" :src="smapleFile" alt="" />
          <div class="design-file-download-btn">
            <a target="_blank" :href="designFile" download
              >Download Design File <i class="ri-download-2-line"></i
            ></a>
          </div>
        </div>

        <div v-if="jobStatus === 1">
          <button @click="startWrappingJob" class="start-wrapping-btn my-4">
            <span v-if="initiateWrappingRequest"
              >Initiating Wrapping Job...</span
            >
            <span v-else> Start Wrapping Job </span>
          </button>
        </div>
        <div v-if="jobStatus === 2" class="my-4">
          <button
            class="primary-bseen-btn m-auto"
            @click="
              $router.push({ name: 'FinishJob', params: { id: job._id } })
            "
          >
            Finish The Job
          </button>
        </div>
      </div>
      <div v-else>
        <p class="text-center my-4">
          Sorry there is no record for the selected campaign!
        </p>
      </div>
    </b-skeleton-wrapper>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import TopWrapper from "@/components/partner/job/top-wrapper.vue";
import { job } from "@/config/api/partner/job";
import Skelton from "@/components/partner/job/Skelton";
export default {
  components: {
    Layout,
    TopWrapper,
    Skelton,
  },
  created() {
    if (!this.$route.params.id) {
      this.$router.go(-1);
    }
    this.loadPartnerDetails();
  },
  data() {
    return {
      job: null,
      loading: false,
      initiateWrappingRequest: false,
    };
  },
  computed: {
    smapleFile() {
      return this.job && this.job.campaign && this.job.campaign.sample_file;
    },
    designFile() {
      return this.job && this.job.campaign && this.job.campaign.sticker_file;
    },
    jobStatus() {
      return this.job && this.job.status;
    },
  },
  methods: {
    loadPartnerDetails() {
      const api = job.getDetails;
      api.id = this.$route.params.id;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.job = res.data.job;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.loading = false;
        });
      console.log("Load partner!");
    },
    startWrappingJob() {
      const api = job.startWrapping;
      api.data = {
        ad_id: this.job._id,
      };
      this.initiateWrappingRequest = true;
      this.generateAPI(api)
        .then(() => {
          this.$router.push({ name: "WrappingMessages" });
        })
        .catch((err) => {
          this.$bvToast.toast(`Wrapping is not started, ${err.response.data}`, {
            title: "Job Status",
            variant: "danger",
            solid: true,
          });
          console.log();
        })
        .finally(() => {
          this.initiateWrappingRequest = false;
        });
    },
  },
};
</script>

<style>
.job-details-sample {
  display: block;
  margin: auto;
  margin-top: 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 480px;

}
.design-file-download-btn {
  text-align: center;
  padding-top: 28px;
}
.design-file-download-btn a {
  background-color: #f1b62a;
  border-radius: 8px;
  padding: 6px 8px;
  color: black;
  font-weight: 900;
}
.design-file-download-btn a:hover {
  opacity: 0.9;
}
.start-wrapping-btn {
  background-color: #f1b62a;
  border: none;
  font-weight: 900;
  min-width: 260px;
  margin-top: 18px;
  display: block;
  margin-left: auto;
  border-radius: 8px;
  padding: 6px 28px;
}
</style>
